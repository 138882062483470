import { defaultHelpMeNowQuestion, type TimelineFeatures } from "@expert/features";
import { env } from "./env";

export const envName = env.envName;
export const isProd = env.isProd;

export const timelineFeatures: TimelineFeatures = {
    helpMeNowQuestion: defaultHelpMeNowQuestion,
    isAutoCompleteEnabled: false,
    isAutoCompleteTestGroup: false,
    isHelpMeNowEnabled: false,
    isOutageAlertInfoEnabled: false,
    isProactiveEnabled: false,
    isReactiveCallContextEnabled: false,
    isReactiveCallContextV2Enabled: false,
    isSalesFAQEnabled: false,
};

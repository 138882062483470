import { useState } from "react";
import { Flex, Menu as MantineMenu, MenuDivider, Space, Text, UnstyledButton } from "@mantine/core";
import { useDisclosure, useClipboard } from "@mantine/hooks";
import { FeedbackModal } from "@soluto-private/expert-workspace-feedback";
import { sendActionEvent } from "../../analytics";
import { useExpertAssistStore, useLoggerWithContextStore } from "../../state";
import { isProd } from "../../utils";
import { type FeedbackNotificationType, useFeedbackPayload } from "../feedback";
import { DevToolsMenuItem } from "../config-selectors";
import classes from "./styles.module.css";
import { CopyIcon, CaretDown, Megaphone } from "@expert/common-ui/assets";
import { getSessionDetails } from "../../state";
import { env } from "../../utils/env";

interface MenuProps {
    onClose: (type: FeedbackNotificationType) => void;
}

export function Menu({ onClose }: MenuProps) {
    const [menuDropdownOpened, setMenuDropdownOpened] = useState(false);
    const {
        token,
        expertProfile: { firstName, lastName },
    } = useExpertAssistStore();

    const { expertId, sessionId, callSid, partner, lob } = getSessionDetails();

    const payload = useFeedbackPayload();
    const { loggerWithContext } = useLoggerWithContextStore();
    const localLogger = loggerWithContext.child({ module: "Menu" });

    const { copy } = useClipboard({ timeout: 500 });

    const copyDebugInfo = () => {
        const debugInfo = {
            SessionId: sessionId ?? "undefined",
            ExpertId: expertId ?? "undefined",
            CallSid: callSid ?? "undefined",
            Partner: partner ?? "undefined",
            LOB: lob ?? "undefined",
            CommitHash: env.commitHash ?? "undefined",
            AppName: env.appName ?? "undefined",
            Timestamp: new Date().toISOString(),
        };

        copy(JSON.stringify(debugInfo));
        localLogger.info("Copied troubleshoot info");

        setMenuDropdownOpened(false);
    };

    const [opened, { open, close }] = useDisclosure(false, {
        onOpen: () => {
            setMenuDropdownOpened(false);
            sendActionEvent({
                actionId: "ShareFeedback",
                actionType: "Click",
                attributes: { source: "Menu" },
            });
            localLogger.info("Feedback modal in menu opened");
        },
        onClose: () => {
            onClose("success");
        },
    });
    const onError = () => {
        onClose("error");
    };

    const onMenuClick = () => {
        sendActionEvent({
            actionId: "agent-profile-menu-view",
            actionType: "Click",
        });
    };

    return (
        <div>
            <FeedbackModal
                isOpen={opened}
                onClose={close}
                onError={onError}
                logger={loggerWithContext}
                feedbackPayload={{ ...payload, caller: "StandaloneHeader", ssoAccessToken: token ?? "" }}
            />
            <MantineMenu
                closeOnClickOutside
                closeOnItemClick={false}
                opened={menuDropdownOpened}
                onChange={setMenuDropdownOpened}
            >
                <MantineMenu.Target>
                    <UnstyledButton
                        mr="lg"
                        size="sm"
                        variant="subtle"
                        data-testid="menu-trigger-button"
                        className={classes.menuButton}
                        onClick={onMenuClick}
                    >
                        <Flex align="center">
                            <div className={classes.icon}>
                                <Text size="sm" className={classes.menuText}>
                                    {(firstName || lastName).charAt(0)}
                                </Text>
                            </div>
                            <Space w="xs" />
                            <Text size="sm" data-testid="expert-name">
                                {firstName} {lastName}
                            </Text>
                            <Space w="xs" />
                            <CaretDown />
                        </Flex>
                    </UnstyledButton>
                </MantineMenu.Target>
                <MantineMenu.Dropdown maw="17.5rem">
                    <MantineMenu.Item
                        leftSection={<Megaphone />}
                        variant="subtle"
                        className={classes.menuItemButton}
                        onClick={open}
                        data-testid="share-feedback-button"
                    >
                        Share your feedback
                    </MantineMenu.Item>
                    <MantineMenu.Item
                        leftSection={<CopyIcon width={22} height={18} stroke="var(--mantine-color-dark-3)" />}
                        variant="subtle"
                        className={classes.menuItemButton}
                        onClick={copyDebugInfo}
                        data-testid="copy-troubleshoot-button"
                    >
                        Copy troubleshoot info
                    </MantineMenu.Item>
                    {!isProd && (
                        <>
                            <MenuDivider />
                            <MantineMenu.Label>Dev tools</MantineMenu.Label>
                            <DevToolsMenuItem />
                        </>
                    )}
                </MantineMenu.Dropdown>
            </MantineMenu>
        </div>
    );
}

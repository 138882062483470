import { useEffect, useState } from "react";
import { useOutageInfoStore } from "../store";
import { useReactAnalytics } from "@soluto-private/eventualize-react";
import { trackFullStoryEvent } from "@expert/monitoring";

interface AlertHandling {
    count: number;
    outageInfo?: string;
    isAlertOpen: boolean;
    onCloseAlert: () => void;
}

export const useHandleAlerts = (): AlertHandling => {
    const { count, outageInfo, setOutageCount, setOutageInfo } = useOutageInfoStore();
    const { dispatcher } = useReactAnalytics();

    const [isAlertOpen, setIsAlertOpen] = useState(!!outageInfo);

    const onCloseAlert = () => {
        setIsAlertOpen(false);
        setOutageInfo(undefined);
        setOutageCount(0);
        void dispatcher.dispatchBusinessEvent("Outage_AlertDismissed", {
            outageInfo,
        });
        trackFullStoryEvent("Outage_AlertDismissed", { outageInfo });
    };

    useEffect(() => {
        if (outageInfo) {
            setIsAlertOpen(true);
        }
    }, [outageInfo]);

    return { count, outageInfo, isAlertOpen, onCloseAlert };
};

import type { SVGProps } from "react";

export function ColorPalette(props: SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20C12.0304 20 12.0608 20.0014 12.0911 20.0042C12.1535 20.0099 12.2162 19.9959 12.2702 19.9641C12.3243 19.9324 12.3671 19.8845 12.3926 19.8272C12.418 19.77 12.4249 19.7061 12.4122 19.6447C12.4042 19.6058 12.3885 19.5691 12.3664 19.5366C11.8519 19.2118 11.447 18.7374 11.2076 18.175C10.9454 17.5592 10.8966 16.8735 11.0691 16.2268C11.2415 15.5801 11.6253 15.0096 12.1593 14.6061C12.6909 14.2044 13.3411 13.9912 14.0073 14H16.5C17.463 14 18.3676 13.6582 19.0177 13.0805C19.6636 12.5066 20 11.7543 20 11C20 7.24178 16.5317 4 12 4ZM4.92893 4.92893C6.8043 3.05357 9.34784 2 12 2C17.4083 2 22 5.92222 22 11C22 12.3657 21.3884 13.6494 20.3463 14.5755C19.3084 15.4978 17.923 16 16.5 16H14L13.9839 15.9999C13.7608 15.9963 13.543 16.0674 13.3649 16.2019C13.1869 16.3364 13.059 16.5265 13.0015 16.7421C12.9441 16.9576 12.9603 17.1862 13.0477 17.3915C13.1351 17.5968 13.2886 17.7669 13.4839 17.8749C13.554 17.9137 13.6192 17.9607 13.6781 18.0151C14.0314 18.341 14.2737 18.7695 14.3709 19.2402C14.4681 19.7109 14.4154 20.2003 14.2202 20.6395C14.025 21.0788 13.697 21.4458 13.2825 21.6891C12.8826 21.9237 12.4214 22.0319 11.9596 21.9999C9.32205 21.9893 6.79478 20.9369 4.92893 19.0711C3.05357 17.1957 2 14.6522 2 12C2 9.34784 3.05357 6.8043 4.92893 4.92893ZM10.5 7.5C10.5 6.39543 11.3954 5.5 12.5 5.5C13.6046 5.5 14.5 6.39543 14.5 7.5C14.5 8.60457 13.6046 9.5 12.5 9.5C11.3954 9.5 10.5 8.60457 10.5 7.5ZM6.5 10.5C6.5 9.39543 7.39543 8.5 8.5 8.5C9.60457 8.5 10.5 9.39543 10.5 10.5C10.5 11.6046 9.60457 12.5 8.5 12.5C7.39543 12.5 6.5 11.6046 6.5 10.5ZM14.5 10.5C14.5 9.39543 15.3954 8.5 16.5 8.5C17.6046 8.5 18.5 9.39543 18.5 10.5C18.5 11.6046 17.6046 12.5 16.5 12.5C15.3954 12.5 14.5 11.6046 14.5 10.5Z"
                fill={props.fill ?? "var(--mantine-color-dark-3"}
            />
        </svg>
    );
}

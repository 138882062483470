import type { AlertProps as MantineAlertProps } from "@mantine/core";
import { Alert, Transition } from "@mantine/core";
import { AlertIcon } from "./AlertIcon";

interface AlertProps extends MantineAlertProps {
    content?: string;
    count: number;
    isOpen: boolean;
    onClose: () => void;
}

export function OutageAlert({ content, count, isOpen, onClose, ...rest }: AlertProps): JSX.Element | null {
    const title = `Down detector - (${count}) ongoing issue${count === 1 ? "" : "s"}`;

    return (
        <Transition duration={400} mounted={!!content && isOpen} transition="fade" timingFunction="ease">
            {(transitionStyles) => (
                <Alert
                    icon={<AlertIcon />}
                    onClose={onClose}
                    title={title}
                    withCloseButton
                    style={{ ...transitionStyles }}
                    styles={{
                        closeButton: { color: "var(--mantine-color-white)" },
                        message: { overflow: "scroll", maxHeight: "2.5rem" },
                        root: { maxHeight: "5.5rem", zIndex: "var(--mantine-priority-high)" },
                        title: { color: "var(--mantine-color-white)" },
                    }}
                    {...rest}
                >
                    {content}
                </Alert>
            )}
        </Transition>
    );
}

import type { SVGProps } from "react";

export function CaretUp({ height = 24, width = 24, fill, ...rest }: SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.9108 8.91083C12.2363 8.58539 12.7639 8.58539 13.0893 8.91083L18.0893 13.9108C18.4148 14.2363 18.4148 14.7639 18.0893 15.0893C17.7639 15.4148 17.2363 15.4148 16.9108 15.0893L12.5001 10.6786L8.08934 15.0893C7.7639 15.4148 7.23626 15.4148 6.91083 15.0893C6.58539 14.7639 6.58539 14.2363 6.91083 13.9108L11.9108 8.91083Z"
                fill={fill ?? "var(--mantine-color-dark-0)"}
            />
        </svg>
    );
}

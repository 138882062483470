import type { SVGProps } from "react";

export function DragIcon(props: SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.2929 0.292893C9.68342 -0.0976311 10.3166 -0.0976311 10.7071 0.292893L13.7071 3.29289C14.0976 3.68342 14.0976 4.31658 13.7071 4.70711C13.3166 5.09763 12.6834 5.09763 12.2929 4.70711L11 3.41421V7C11 7.55228 10.5523 8 10 8C9.44772 8 9 7.55228 9 7V3.41421L7.70711 4.70711C7.31658 5.09763 6.68342 5.09763 6.29289 4.70711C5.90237 4.31658 5.90237 3.68342 6.29289 3.29289L9.2929 0.292893ZM4.70711 6.29289C5.09763 6.68342 5.09763 7.31658 4.70711 7.70711L3.41421 9H7C7.55229 9 8 9.44771 8 10C8 10.5523 7.55229 11 7 11H3.41421L4.70711 12.2929C5.09763 12.6834 5.09763 13.3166 4.70711 13.7071C4.31658 14.0976 3.68342 14.0976 3.29289 13.7071L0.292893 10.7071C-0.0976311 10.3166 -0.0976311 9.68342 0.292893 9.29289L3.29289 6.29289C3.68342 5.90237 4.31658 5.90237 4.70711 6.29289ZM15.2929 6.29289C15.6834 5.90237 16.3166 5.90237 16.7071 6.29289L19.7071 9.29289C20.0976 9.68342 20.0976 10.3166 19.7071 10.7071L16.7071 13.7071C16.3166 14.0976 15.6834 14.0976 15.2929 13.7071C14.9024 13.3166 14.9024 12.6834 15.2929 12.2929L16.5858 11H13C12.4477 11 12 10.5523 12 10C12 9.44771 12.4477 9 13 9H16.5858L15.2929 7.70711C14.9024 7.31658 14.9024 6.68342 15.2929 6.29289ZM9 16.5858V13C9 12.4477 9.44772 12 10 12C10.5523 12 11 12.4477 11 13V16.5858L12.2929 15.2929C12.6834 14.9024 13.3166 14.9024 13.7071 15.2929C14.0976 15.6834 14.0976 16.3166 13.7071 16.7071L10.7071 19.7071C10.3166 20.0976 9.68342 20.0976 9.2929 19.7071L6.29289 16.7071C5.90237 16.3166 5.90237 15.6834 6.29289 15.2929C6.68342 14.9024 7.31658 14.9024 7.70711 15.2929L9 16.5858Z"
                fill={props.fill ?? "var(--mantine-color-dark-3"}
            />
        </svg>
    );
}

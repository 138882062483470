import { getLogger } from "@expert/logging";
import { Image, type MantineTransition, ScrollArea, Transition } from "@mantine/core";
import { useHover, useMergedRef, useMouse, useToggle } from "@mantine/hooks";
import { useReactAnalytics } from "@soluto-private/eventualize-react";
import { type ImgHTMLAttributes, useEffect, useState } from "react";
import { preferWebpFormat } from "../utils";
import type { DATASET_MESSAGE_ID_ATTRIBUTE_KEY } from "../../processing";

const logger = getLogger({ module: "ImageZoomPan" });

interface ImageZoomPanProps extends ImgHTMLAttributes<HTMLImageElement> {
    [DATASET_MESSAGE_ID_ATTRIBUTE_KEY]: string;
}

export function ImageZoomPan({ src, title, "data-message-id": messageId }: ImageZoomPanProps) {
    const [isDisplayingImage, setIsDisplayingImage] = useState<boolean>(false);
    const { dispatcher } = useReactAnalytics();
    const [isZoomIn, toggleZoomIn] = useToggle([false, true] as const);
    const { ref: mouseRef, x, y } = useMouse();
    const { ref: hoverRef, hovered } = useHover();
    const ref = useMergedRef(hoverRef, mouseRef);

    const scaleImage: MantineTransition = {
        in: { transform: `scale(3)` },
        out: { transform: `unset` },
        common: { transformOrigin: `${x}px ${y}px` },
        transitionProperty: "transform",
    };

    const onImageClick = () => {
        logger.info({ url: src, title, isZoomIn }, "multi modal image clicked");
        void dispatcher.dispatchBusinessEvent("MultiModal_Click_CollageImage", {
            source: "Message",
            url: src,
            actionId: "CollageImage",
            title,
            messageId,
        });
        toggleZoomIn();
    };

    const onImageLoad = () => {
        logger.debug({ url: src, title, messageId }, "multi modal image loaded successfully");
        setIsDisplayingImage(true);
    };

    const onImageLoadError = () => {
        logger.warn({ url: src, title, messageId }, "multi modal image failed to load");
        setIsDisplayingImage(false);
    };

    const onImageZoomIn = () => {
        logger.debug({ url: src, title, messageId }, "multi modal image zoomed in");
    };

    const onImageZoomOut = () => {
        logger.debug({ url: src, title, messageId }, "multi modal image zoomed out");
    };

    useEffect(() => {
        if (!hovered) {
            toggleZoomIn(false);
        }
    }, [hovered, toggleZoomIn]);

    return (
        <ScrollArea.Autosize
            display={isDisplayingImage ? "inline" : "none"}
            ref={ref}
            type="never"
            mt="md"
            style={{
                borderRadius: "var(--mantine-radius-md)",
                cursor: isZoomIn ? "zoom-out" : "zoom-in",
            }}
            onClick={onImageClick}
        >
            <Transition
                mounted={isZoomIn}
                transition={scaleImage}
                duration={300}
                exitDuration={300}
                timingFunction="ease-in-out"
                keepMounted
                onEntered={onImageZoomIn}
                onExited={onImageZoomOut}
            >
                {(transitionStyle) => (
                    <Image
                        src={preferWebpFormat(src)}
                        radius="md"
                        onLoad={onImageLoad}
                        onError={onImageLoadError}
                        fit="contain"
                        style={{
                            ...transitionStyle,
                            display: "inline-block",
                        }}
                    />
                )}
            </Transition>
        </ScrollArea.Autosize>
    );
}

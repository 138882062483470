import { Autocomplete, Center, Container, Flex } from "@mantine/core";
import { useFocusWithin, useHotkeys } from "@mantine/hooks";
import { type KeyboardEvent, type SyntheticEvent, useEffect, useRef, useState } from "react";
import { useReactAnalytics } from "@soluto-private/eventualize-react";
import { type TimelineFeatures, useFeatures } from "@expert/features";
import type { Logger } from "@expert/logging";
import { trackFullStoryEvent } from "@expert/monitoring";
import { useTimelineStore } from "../../state";
import { useAutoscroll } from "../../utils";
import type { GuideCommand } from "../../shared-types";
import { useHandleAutoComplete, useSendExpertAssistMessage } from "../../hooks";
import classes from "./MessageInput.module.css";
import { SendButton } from "./SendButton";
import { ToolboxButton } from "./ToolboxButton";

const formatMessage = (msg: string) => {
    return msg.replace(/\n+$/, ""); // remove all trailing newline characters
};

interface MessageInputProps {
    guide?: GuideCommand;
    logger: Logger;
    scrollRef?: React.RefObject<HTMLDivElement>;
}

export function MessageInput({ guide, logger, scrollRef }: MessageInputProps): JSX.Element {
    const localLogger = logger.child({ module: "MessageInput" });

    const { dispatcher } = useReactAnalytics();
    const { shouldAutoOpenToolbox, setShouldAutoOpenToolbox, autoCompleteSuggestions, setNewSuggestions } =
        useTimelineStore();
    const {
        features: { isAutoCompleteEnabled, isAutoCompleteTestGroup },
    } = useFeatures<TimelineFeatures>();
    const sendMessage = useSendExpertAssistMessage(logger);
    const { start: startAutoscroll } = useAutoscroll(scrollRef);

    const [input, setInput] = useState("");
    const inputRef = useRef<HTMLInputElement>(null);
    const [previousInput, setPreviousInput] = useState("");
    const [selectedSuggestion, setSelectedSuggestion] = useState("");

    const isSendDisabled = ["", "/"].includes(input.trimEnd());
    const toolboxInput = input.startsWith("/") ? input : undefined;

    // TODO: implement a more robust PII detection mechanism while still avoiding UI lag
    const containsPotentialPii = /(?:\d\D*){4,}/.test(input); // check if input contains 4 or more digits

    const [toolboxOpened, setToolboxOpened] = useState(false);
    const [toolboxTrapFocus, setToolboxTrapFocus] = useState(false);
    const [showAutocompleteSuggestions, setShowAutocompleteSuggestions] = useState(false);
    const { ref, focused } = useFocusWithin();

    // TODO:
    // remove this click analytic event and isAutoCompleteTestGroup FF
    // once autocomplete functional testing/scaling is complete
    const sendAutoCompleteInputAnalytic = () => {
        void dispatcher.dispatchBusinessEvent("Click_MessageInput", { input });
    };

    const handleInputChange = (newInputValue: string) => {
        setInput(newInputValue);

        // clear autocomplete suggestions when user deletes all input
        if (newInputValue.trimEnd() === "") {
            setNewSuggestions([]);
        }

        const shouldOpenToolbox = newInputValue.startsWith("/");
        if (shouldOpenToolbox && !toolboxOpened) {
            void dispatcher.dispatchAction("Shortcut", "OpenToolbox", {});
        }
        setToolboxOpened(shouldOpenToolbox || false);
    };

    const handleSubmit = (event: SyntheticEvent) => {
        event.preventDefault();

        if (selectedSuggestion) {
            void dispatcher.dispatchBusinessEvent("AutoComplete_SuggestionUsed", {
                suggestion: selectedSuggestion,
                sentInput: input,
                previousInput,
                suggestionEdited: input !== selectedSuggestion,
            });
            if (input !== selectedSuggestion) {
                localLogger.info(
                    {
                        previousInput,
                        suggestion: selectedSuggestion,
                        sentInput: input,
                    },
                    "AutoComplete | Expert message sent to model with edited autocomplete suggestion",
                );
            }
            setSelectedSuggestion("");
            setPreviousInput("");
        }

        const type = "Expert";
        const messageId = crypto.randomUUID();
        void dispatcher.dispatchAction("Click", "SendMessage", { messageType: type, messageId });

        const formattedInput = formatMessage(input);
        sendMessage({ text: formattedInput, type, id: messageId });

        setInput("");
        setNewSuggestions([]);

        startAutoscroll();
    };
    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === "Enter" && !event.shiftKey && !isSendDisabled) {
            // TODO: https://solutonashville.atlassian.net/browse/EWS-913
            const hasActiveAutocompleteSuggestion = !!document.querySelector<HTMLDivElement>(
                `[data-combobox-option][data-combobox-selected]`,
            );
            if (!hasActiveAutocompleteSuggestion) handleSubmit(event);
        }
        setToolboxTrapFocus((toolboxOpened && ["ArrowDown", "ArrowUp"].includes(event.key)) || false);
    };
    const handleToolboxClose = () => {
        if (toolboxInput) {
            setInput("");
        }
    };

    const { cancel, handleSuggestionClick } = useHandleAutoComplete({
        input,
        logger,
        toolboxInput,
        isSendDisabled,
        setInput,
        setPreviousInput,
        setSelectedSuggestion,
    });

    // auto-open toolbox on load for visibility to experts on new toolbox feature
    useEffect(() => {
        if (!shouldAutoOpenToolbox) return;
        setToolboxOpened(shouldAutoOpenToolbox);
        setShouldAutoOpenToolbox(false);
    }, [setToolboxOpened, shouldAutoOpenToolbox, setShouldAutoOpenToolbox]);

    useHotkeys(
        [
            [
                "/",
                () => {
                    if (!toolboxOpened) {
                        setToolboxOpened(true);
                        inputRef.current?.focus();
                        setInput(`${input}/`);
                        void dispatcher.dispatchAction("Shortcut", "OpenToolbox", {});
                    }
                },
            ],
        ],
        undefined,
        true,
    );

    useEffect(() => {
        const shouldShow = autoCompleteSuggestions.length > 0 && !!input && focused && !toolboxOpened;
        const didChange = shouldShow !== showAutocompleteSuggestions;
        if (!didChange) return;

        setShowAutocompleteSuggestions(shouldShow);
        if (shouldShow) {
            const didShowWithFilter = autoCompleteSuggestions.filter((value) => value.includes(input)).length > 0;
            const extraData = { suggestions: autoCompleteSuggestions, didShowWithFilter, input };
            const actionId = "AutoComplete_SuggestionShown";
            localLogger.info(extraData, "AutoComplete | suggestion shown in message input dropdown");
            void dispatcher.dispatchBusinessEvent(actionId, extraData);
            trackFullStoryEvent(actionId, extraData);
        }
    }, [toolboxOpened, autoCompleteSuggestions, input, focused, dispatcher, showAutocompleteSuggestions, localLogger]);

    return (
        <Center className={classes.inputContainer} ref={ref}>
            <Container className={classes.inputBackground} />
            <Flex className={classes.inputWrapper} justify="center">
                <Autocomplete
                    className={containsPotentialPii ? "fs-mask" : undefined}
                    classNames={{ input: classes.input, option: classes.option }}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    data={isAutoCompleteEnabled ? autoCompleteSuggestions : []}
                    // returning all options here removes default "contains" filter for mantine autocomplete
                    filter={({ options }) => options}
                    dropdownOpened={showAutocompleteSuggestions}
                    comboboxProps={{
                        position: "top-start",
                        size: "sm",
                        width: "fill",
                        zIndex: "var(--mantine-priority-highest)",
                    }}
                    placeholder="Ask Assist or type / to open the toolbox"
                    radius="xl"
                    leftSection={
                        <ToolboxButton
                            opened={toolboxOpened}
                            setOpened={setToolboxOpened}
                            onClose={handleToolboxClose}
                            guide={guide}
                            input={toolboxInput}
                            trapFocus={toolboxTrapFocus}
                            logger={logger}
                        />
                    }
                    leftSectionWidth="4.25rem"
                    onBlur={cancel}
                    onClick={
                        isAutoCompleteEnabled || isAutoCompleteTestGroup ? sendAutoCompleteInputAnalytic : undefined
                    }
                    onOptionSubmit={(option) => {
                        handleSuggestionClick(option);
                    }}
                    rightSection={<SendButton isDisabled={isSendDisabled} onClick={handleSubmit} />}
                    rightSectionWidth={64}
                    size="xl"
                    style={{ width: "100%", input: { fz: "sm" } }}
                    value={input}
                    variant="filled"
                    data-testid="timeline-message-input"
                    id="timeline-message-input"
                    ref={inputRef}
                />
            </Flex>
        </Center>
    );
}

import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

export interface OutageInfoStore {
    count: number;
    outageInfo?: string;
    setOutageCount: (count: number) => void;
    setOutageInfo: (info?: string) => void;
}

export const useOutageInfoStore = create<OutageInfoStore>()(
    immer(
        devtools(
            (set) => ({
                count: 0,
                outageInfo: undefined,
                setOutageCount: (count: number) => {
                    set(
                        (state) => {
                            state.count = count;
                        },
                        false,
                        "setOutageCount",
                    );
                },
                setOutageInfo: (info?: string) => {
                    set(
                        (state) => {
                            state.outageInfo = info;
                        },
                        false,
                        "setOutageInfo",
                    );
                },
            }),
            {
                enabled: import.meta.env.MODE !== "production",
                store: "outage-info-store",
                name: "outage-info",
            },
        ),
    ),
);

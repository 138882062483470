export function AlertIcon(): JSX.Element {
    return (
        <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.80729 17.4166C14.1795 17.4166 17.724 13.8722 17.724 9.49992C17.724 5.12766 14.1795 1.58325 9.80729 1.58325C5.43504 1.58325 1.89062 5.12766 1.89062 9.49992C1.89062 13.8722 5.43504 17.4166 9.80729 17.4166Z"
                fill="#933ADC"
            />
            <path
                d="M9.80859 6.33325V9.49992"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.80859 12.6667H9.81651"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

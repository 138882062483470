import type { TimelineFeatures } from "@expert/features";

export const getExperimentName = ({
    isReactiveCallContextEnabled,
    isReactiveCallContextV2Enabled,
    isSalesFAQEnabled,
    lob,
}: Pick<TimelineFeatures, "isReactiveCallContextEnabled" | "isReactiveCallContextV2Enabled" | "isSalesFAQEnabled"> & {
    lob?: string;
}) => {
    // care/mobility workflows do not support other features at this time
    if (lob === "care" || lob === "mobility") return undefined;
    // gaia workflows support one experiment at a time - the first one that is enabled will be used
    if (isSalesFAQEnabled) return "sales-faq";
    if (isReactiveCallContextV2Enabled) return "call-context-v2";
    if (isReactiveCallContextEnabled) return "call-context";
};

export const getSupportedLineOfBusiness = ({ partner, lob }: { partner: string; lob?: string }) => {
    // verizon is the only partner that supports multiple lines of business at this time
    if (partner === "verizon") return lob;
    return undefined;
};

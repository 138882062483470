import { type TimelineFeatures, useFeatures } from "@expert/features";
import { useGaiaWebsocket } from "@expert/gaia";
import { useReactAnalytics } from "@soluto-private/eventualize-react";
import type { Logger } from "@expert/logging";
import type { OrchestratorInvokeMessagePayload } from "@expert/shared-types";
import { useTimelineStore } from "../state";

export function useRequestAutoCompleteSuggestions(logger: Logger) {
    const websocketObj = useGaiaWebsocket();
    const { dispatcher } = useReactAnalytics();
    const { callSid, expertId, partner, sessionId } = useTimelineStore();
    const {
        features: { isAutoCompleteEnabled },
    } = useFeatures<TimelineFeatures>();

    if (!isAutoCompleteEnabled || !callSid || !sessionId) {
        return {};
    }

    const requestAutoCompleteSuggestions = (textInput: string) => {
        const fetchAutoCompleteSuggestionsPayload: OrchestratorInvokeMessagePayload = {
            action: "orchestrator-invoke",
            appName: "expert-assist-autocomplete",
            correlationId: crypto.randomUUID(),
            message: textInput,
            messageId: crypto.randomUUID(),
            messageType: "AutoComplete",
            partner,
            sessionId: callSid,
            sessionGroupId: sessionId,
            userId: expertId,
            userType: "expert",
        };
        logger
            .child({ module: "useRequestAutoCompleteSuggestions" })
            .info({ input: textInput }, "AutoComplete | suggestion requested");
        void dispatcher.dispatchBusinessEvent("AutoComplete_SuggestionRequested", { input: textInput });

        websocketObj?.sendJsonMessage(fetchAutoCompleteSuggestionsPayload);
    };

    return { requestAutoCompleteSuggestions };
}

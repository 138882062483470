import type { SVGProps } from "react";

export function Wand({ height = 22, width = 22, fill, ...rest }: SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <path
                d="M1.5625 20.5833L12.3958 9.75"
                stroke={fill ?? "var(--mantine-color-dark-3)"}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.2305 7.25H20.7305"
                stroke={fill ?? "var(--mantine-color-dark-3)"}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17.3945 4.74998L19.0612 3.08331"
                stroke={fill ?? "var(--mantine-color-dark-3)"}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.8945 3.91666V1.41666"
                stroke={fill ?? "var(--mantine-color-dark-3)"}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.3971 4.74998L10.7305 3.08331"
                stroke={fill ?? "var(--mantine-color-dark-3)"}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.5625 7.25H9.0625"
                stroke={fill ?? "var(--mantine-color-dark-3)"}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.8945 9.75V12.25"
                stroke={fill ?? "var(--mantine-color-dark-3)"}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17.3945 8.91666L19.0612 10.5833"
                stroke={fill ?? "var(--mantine-color-dark-3)"}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

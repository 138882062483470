export type SolveFeatures = TimelineFeatures & WorkspaceFeatures;

export interface TimelineFeatures {
    helpMeNowQuestion: string;
    isAutoCompleteEnabled: boolean;
    isAutoCompleteTestGroup: boolean;
    isHelpMeNowEnabled: boolean;
    isOutageAlertInfoEnabled: boolean;
    isProactiveEnabled: boolean;
    isReactiveCallContextEnabled: boolean;
    isReactiveCallContextV2Enabled: boolean;
    isSalesFAQEnabled: boolean;
}

export const defaultHelpMeNowQuestion = "Help the customer with their technical problem";

export interface WorkspaceFeatures {
    isConversationalIVRGuideEnabled: boolean;
    isDeviceActivationGuideEnabled: boolean;
    isDeviceReplacementInfoEnabled: boolean;
    isProactiveEnabled: boolean;
    isPreviousSessionSummaryEnabled: boolean;
    isVerizonCareEnabled: boolean;
}

export type TimelineContextType = Record<keyof TimelineFeatures, boolean | number | string>;
export type WorkspaceContextType = Record<keyof WorkspaceFeatures, boolean>;

// group name used for analytics
export type Group = "Timeline" | "Workspace";

import { useGaiaWebsocket } from "@expert/gaia";
import type { Logger } from "@expert/logging";
import { useGaiaOutageInfo } from "./useGaiaOutageInfo";

export const useFetchOutageInfo = (logger: Logger) => {
    useGaiaOutageInfo(logger);
    const websocketObj = useGaiaWebsocket();

    const fetchOutageInfo = () => {
        const payload = {
            action: "fetch-helix-news",
            correlationId: crypto.randomUUID(),
        };
        websocketObj?.sendJsonMessage(payload);
    };

    return { fetchOutageInfo };
};

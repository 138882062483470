import type { ExpertAssistMessage, MessageType } from "../shared-types";
import { useTimelineStore } from "./timelineStore";

export const getLastMessage = () => {
    const { messages } = useTimelineStore.getState();
    return messages.length > 0 ? messages[messages.length - 1] : undefined;
};

export const getLastMessageOfType = (messages: ExpertAssistMessage[], types: MessageType[]) => {
    return messages.filter((message) => types.includes(message.type)).pop()?.id;
};

export const getMessage = (id: string) => {
    const { messages } = useTimelineStore.getState();
    return messages.filter((msg) => msg.id === id)?.[0];
};

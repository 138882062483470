import type { ReactiveMessagePayload } from "@expert/shared-types";
import { generateOzmoEmulatorUrl, generateOzmoTutorialUrl } from "./generateOzmoUrl";

export const DATASET_MESSAGE_ID_ATTRIBUTE_KEY = `data-message-id`;

interface ProcessedMessage {
    response: string;
    analytics?: Record<string, unknown>;
}

export function processMessage({
    response,
    externalDevices,
    tutorials,
    multiModal,
    sessionGroupId,
    sessionId,
    messageId,
    metadata,
}: ReactiveMessagePayload): ProcessedMessage {
    const dataMessageAttribute = `${DATASET_MESSAGE_ID_ATTRIBUTE_KEY}="${messageId}"`;
    const processedMessage = {
        response,
        analytics: { messageIntent: metadata?.intentClassification ?? "unknown" } as Record<string, unknown>,
    };

    if (tutorials && tutorials.length > 0) {
        const latestTutorial = tutorials[tutorials.length - 1];
        const url = generateOzmoTutorialUrl(latestTutorial, sessionGroupId, sessionId);
        const ozmoMarkdown = `<a href="${url}" ozmo actionId="OpenTutorial" title="${latestTutorial.name}" ${dataMessageAttribute}>${latestTutorial.name}</a>`;

        processedMessage.response = `${response}\n\nView in Ozmo: ${ozmoMarkdown}`;
        processedMessage.analytics = {
            ...processedMessage.analytics,
            includesOzmoTutorialLink: true,
            mediaUrls: [url],
            mediaTitles: [latestTutorial.name],
        };
    } else if (externalDevices && externalDevices.length > 0) {
        /*
        If there's 1 device, show in a single line like:
            View in Ozmo: Device 1
        If there's more than 1 device, show the emulator links as a list like:
            View in Ozmo:
            - Device 1
            - Device 2
        */

        const shouldShowAsList = externalDevices.length > 1;
        let updatedResponse = `${response}\n\nView in Ozmo: ${shouldShowAsList ? "\n" : ""}`;
        const mediaUrls: string[] = [];
        const mediaTitles: string[] = [];

        externalDevices.forEach((device) => {
            const url = generateOzmoEmulatorUrl(device.model, sessionGroupId, sessionId);
            if (shouldShowAsList) updatedResponse += "* ";
            mediaUrls.push(url);
            mediaTitles.push(device.name);
            updatedResponse += `<a href="${url}" ozmo actionId="OpenEmulator" title="${device.name}" ${dataMessageAttribute}>${device.name}</a>\n`;
        });

        processedMessage.response = updatedResponse;
        processedMessage.analytics = {
            ...processedMessage.analytics,
            includesOzmoEmulatorLink: true,
            mediaUrls,
            mediaTitles,
        };
    }

    if (multiModal) {
        const imageMarkdown = `<img src="${multiModal.collage}" title="${multiModal.title}" ${dataMessageAttribute}/>`;
        processedMessage.response = `${processedMessage.response}\n\n${imageMarkdown}`;
        processedMessage.analytics = {
            ...processedMessage.analytics,
            includesMultiModalImage: true,
            mediaUrls: [multiModal.collage],
            mediaTitles: [multiModal.title],
        };
    }

    return processedMessage;
}

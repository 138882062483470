import { Flex } from "@mantine/core";
import { AnalyticsProvider } from "@soluto-private/eventualize-react";
import { useDefaultMessages } from "@expert/contentful";
import { FeaturesProvider, type TimelineContextType, type TimelineFeatures, useFlags } from "@expert/features";
import { forwardRef, useEffect, useMemo, useRef } from "react";
import type { ExpertAssistMessage, TimelineProps } from "../../shared-types";
import { fallbackDefaultMessages, useTimelineStore } from "../../state";
import { MessageInput } from "../message-input";
import { TimelineList } from "../timeline-list";
import { useAutoscroll } from "../../utils";
import { useGaiaAutoCompleteEvents } from "../../hooks";

export const Timeline = forwardRef<HTMLDivElement, TimelineProps>(function Timeline(
    {
        callSid,
        defaultFeatures,
        expertId,
        expertName,
        featuresContext: context,
        guide,
        isFocused = true,
        logger: loggerProp,
        lob,
        partner,
        sessionId,
        SubscriptionsWithFeatures,
    }: TimelineProps,
    ref,
): JSX.Element {
    const { setExpert, setTimelineProperties, setDefaultMessages } = useTimelineStore();
    const logger = useMemo(() => loggerProp.child({ module: "Timeline" }), [loggerProp]);

    const scrollRef = useRef<HTMLDivElement>(null);
    const { start: startAutoscroll } = useAutoscroll(scrollRef);

    const contentfulDefaultMessages = useDefaultMessages(fallbackDefaultMessages) as ExpertAssistMessage[];
    useGaiaAutoCompleteEvents(logger);

    const features = useFlags<TimelineContextType>({
        analyticGroup: "Timeline",
        context,
        features: defaultFeatures,
        logger: loggerProp,
    }) as TimelineFeatures;

    useEffect(() => {
        logger.info({ expertId, expertName }, "Timeline Setup | expert");
        setExpert({ expertId, expertName });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setExpert, expertId, expertName]);

    useEffect(() => {
        logger.info({ partner, sessionId, callSid, lob }, "Timeline Setup | timeline meta");
        setTimelineProperties({ partner, sessionId, callSid, lob });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setTimelineProperties, partner, sessionId, callSid, lob]);

    useEffect(() => {
        setDefaultMessages(contentfulDefaultMessages);
    }, [setDefaultMessages, contentfulDefaultMessages]);

    useEffect(() => {
        if (callSid) {
            startAutoscroll(); // auto scroll for a new call
        }
    }, [callSid, startAutoscroll]);

    return (
        <AnalyticsProvider
            dispatcher={(analyticsDispatcher) =>
                analyticsDispatcher.withIdentities({ ExpertAssistSessionId: sessionId })
            }
        >
            <Flex
                align="center"
                data-testid="timeline-wrapper"
                direction="column"
                h="100%"
                justify="flex-end"
                style={{ overflow: "hidden" }}
                m="auto"
                ref={ref}
                w="100%"
            >
                <FeaturesProvider features={features}>
                    {/* 
                        some of the subscriptions specific to the workspace and standalone need to be able
                        to access the features context, so we have to pass the subscriptions down as a prop
                        and call it under the features provider
                        
                        be sure to REFACTOR this if the subscriptions do not need the features in the future.
                    */}
                    {SubscriptionsWithFeatures}
                    <TimelineList logger={logger} sessionId={sessionId} scrollRef={scrollRef} isFocused={isFocused} />
                    <MessageInput logger={logger} scrollRef={scrollRef} guide={guide} />
                </FeaturesProvider>
            </Flex>
        </AnalyticsProvider>
    );
});

import type { Expert } from "./mutations";

export interface OnUpdateExpertSubscription {
    onUpdateExpert?: Expert | null;
}

export const onUpdateExpert = /* GraphQL */ `
    subscription OnUpdateExpert(
        $callSid: String
        $partner: String
        $employeeId: String
        $eventType: String
        $sessionId: String
    ) {
        onUpdateExpert(
            callSid: $callSid
            partner: $partner
            employeeId: $employeeId
            eventType: $eventType
            sessionId: $sessionId
        ) {
            callSid
            partner
            employeeId
            eventType
            lastUpdated
            sessionId
            __typename
        }
    }
`;

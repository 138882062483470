import { Flex } from "@mantine/core";
import Player from "react-lottie-player/dist/LottiePlayerLight";
import classes from "../../styles.module.css";
import path from "./successLottie.json?url";

export function FeedbackSuccessAnimation() {
    return (
        <Flex direction="unset" justify="center" align="center">
            <Player className={classes.successAnimation} path={path} play speed={1} loop={false} />
        </Flex>
    );
}

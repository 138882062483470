import { useEffect, useMemo } from "react";
import { type GaiaWebSocketResponse, gaiaWsEventBus } from "@expert/gaia";
import type { Logger } from "@expert/logging";
import { trackFullStoryEvent } from "@expert/monitoring";
import { useReactAnalytics } from "@soluto-private/eventualize-react";
import { useOutageInfoStore } from "../store";

export const useGaiaOutageInfo = (loggerProp: Logger) => {
    const logger = useMemo(() => loggerProp.child({ module: "useGaiaOutageInfo" }), [loggerProp]);
    const { dispatcher } = useReactAnalytics();

    useEffect(() => {
        const gaiaEventBusFetchOutageInfoSuccess = gaiaWsEventBus.on(
            "gaia_ws_fetch-helix-news-success",
            ({ body }: GaiaWebSocketResponse) => {
                const news = body as string[];
                const { outageInfo, setOutageCount, setOutageInfo } = useOutageInfoStore.getState();

                if (news.length > 0) {
                    logger.info(body, "GAIA | Outage Info | websocket received updated helix news info");

                    const formattedEmojiVal: string = news.map((val) => `❗️ ${val}`).join("        ");

                    setOutageCount(news.length);
                    setOutageInfo(formattedEmojiVal);

                    void dispatcher.dispatchBusinessEvent("Outage_InfoReceived", {
                        news,
                    });
                    trackFullStoryEvent("Outage_InfoReceived", { news });
                } else {
                    if (outageInfo) {
                        setOutageCount(0);
                        setOutageInfo(undefined);
                    }
                }
            },
        );

        return () => {
            gaiaEventBusFetchOutageInfoSuccess();
        };
    }, [dispatcher, logger]);
};

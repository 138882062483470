import { useDevices } from "@expert/contentful";
import type { MultipleActionName, MultipleActions } from "./types";
import type { MessageType } from "../../shared-types";

const DEFAULT_DEVICES_LIST: string[] = [];
const TSNR_URL = "https://csp-prod-eastus2-trip-mo.it.att.com/portal/trip_mo/Dashboard_MO";

export function useCreateActions({
    setIsExpanded,
    messageId,
    messageType,
}: {
    setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
    messageId?: string;
    messageType?: MessageType;
}): Record<MultipleActionName, MultipleActions> {
    const devices = useDevices(DEFAULT_DEVICES_LIST);
    const eventData = { messageId, messageType };
    return {
        expandMessage: {
            value: "Show more",
            analytics: {
                actionId: "ExpandMessage",
                eventData,
            },
            onClick: () => {
                setIsExpanded(true);
            },
            isVisibleAfterClick: true,
        },
        neutralOrPositiveFeedback: {
            value: ["Issue resolved", "Still need help"],
            analytics: {
                actionId: "MessageFeedbackDetails",
                eventData,
            },
        },
        negativeFeedback: {
            value: "Wrong issue",
            analytics: {
                actionId: "MessageFeedbackDetails",
                eventData,
            },
        },
        tsnr: {
            value: "Open Trip instead",
            analytics: { actionId: "OpenTSNR", eventData },
            onClick: () => {
                window.open(TSNR_URL, "_blank", "noopener,noreferrer");
            },
            isVisibleAfterClick: true,
        },
        devices: {
            value: devices,
            analytics: { actionId: "DeviceInfo", eventData },
        },
    };
}

import type { Logger } from "@expert/logging";
import { Anchor, Tooltip } from "@mantine/core";
import { useReactAnalytics } from "@soluto-private/eventualize-react";
import type { DATASET_MESSAGE_ID_ATTRIBUTE_KEY } from "../../processing";

export interface OzmoLinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
    logger?: Logger;
    actionId: string;
    ozmo?: boolean;
    [DATASET_MESSAGE_ID_ATTRIBUTE_KEY]: string;
}

export function OzmoLink({
    href,
    title,
    actionId,
    ozmo,
    children,
    logger,
    "data-message-id": messageId,
}: OzmoLinkProps) {
    const { dispatcher } = useReactAnalytics();
    const onClick = () => {
        logger?.child({ module: "OzmoLink" }).info({ url: href, title, messageId }, "Ozmo link clicked");
        const eventName = ozmo ? `Ozmo_${actionId}` : `Click_${actionId || "Link"}`;
        void dispatcher.dispatchBusinessEvent(eventName, {
            source: "Message",
            url: href,
            actionId: actionId || title,
            title,
            messageId,
        });
    };

    return (
        <Tooltip
            label={href}
            position="top-start"
            zIndex={999}
            multiline
            maw="20rem"
            styles={{
                tooltip: {
                    "word-wrap": "break-word",
                },
            }}
        >
            <Anchor href={href} target="_blank" onClick={onClick} size="sm" underline="always" data-testid="OzmoLink">
                {children}
            </Anchor>
        </Tooltip>
    );
}
